import React, { useState } from "react"


const DropDwon = ({ title, children }) => {

    const [status, open] = useState(false)

    return (<div className={`dropdwonTitle ${status ? "open" : ""}`} >

        <h3 onClick={() => open(!status)}>{title}</h3>
        <span class="expander">{status ? "–" : "+"}</span>
        <div className={`desc ${status ? "open" : ""}`}>
            {children}
        </div>
        {/* {status ? <>
        </> : null} */}
    </div>)
}

export { DropDwon };