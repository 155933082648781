import PropTypes from 'prop-types'
import React from 'react'

import Logo from "../images/logo.ico"

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo" style={{ backgroundColor: "rgba(0,0,0,0.75)",height:"150px",width:"150px" }}>
      <img style={{ width: "70%", height: "70%", marginTop: "10%" }} src={Logo}></img>
      {/* <span className="icon fa-diamond"></span> */}
    </div>
    <div className="content">
      <div className="inner">
        <h1>MaDDance</h1>
        <p>
          Nowoczesna szkoła tańca dla każdego! Brakuje Ci ruchu? Pandemia i ciągła praca przed komputerem zniszczyły kondycję? Chcesz się nauczyć tańczyć i jeździć na turnieje? A może brakuje Ci po prostu śmiałości na imprezach? Chcesz w życiu więcej pozytywnej energii? W MaDDance możemy rozwiązać wszystkie powyższe problemy i dużo więcej!
        </p>
        <ul className="icons">
          <li>
            <a href="https://www.facebook.com/MaDdance-Szkoła-Tańca-100812955552938" className="icon fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCnxfizNGkQX-_aMlhAMcdBA" className="icon fa-youtube">
              <span className="label">Youtube</span>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/maddance_szkolatanca/" className="icon fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            O mnie
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Oferta
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Grafik
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('cennik')
            }}
          >
            Cennik
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Kontakt
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
