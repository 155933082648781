import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/DSC_0971.jpg'
import pic02 from '../images/DSC_0638.jpg'
import pic03 from '../images/pic03.jpg'
import { DropDwon } from "./Dropdown"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">O mnie</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Nazywam się Mateusz Szczepański. Z tańcem związany jestem już prawie 20 lat. Uczyłem się u wielu trenerów, również tych światowej klasy, i nadal kontynuuję swoją naukę (i prawdopodobnie jeszcze długo nie przestanę szlifować swoich umiejętności). Brałem udział w dużej liczbie turniejów, część z nich było międzynarodowych, wiele z nich wygrałem. Taniec to moje życie. Nadszedł moment, w którym mogę spełnić swoje marzenie: dzielić się swoją wiedzą i zarażać pasją do tańca. Chcę stworzyć miejsce, gdzie wszyscy będą czuli się komfortowo, gdzie wszyscy będą mogli się rozwijać i zakochiwać w tańcu. Chcę, by MaDDance był miejscem, gdzie możemy się spotkać, by wspólnie dzielić się pasją i wzajemnie motywować się do dalszego rozwoju.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Oferta</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <DropDwon title={"Zajęcia grupowe, tańce latynoamerykańskie"}>
            <>
              <p>
                Chcesz zasmakować karaibskich klimatów w rytmie kubańskiej rumby? A może wybierasz się niedługo do Brazylii i chcesz się nauczyć tańczyć sambę?
                Chcesz zasmakować karaibskich klimatów w rytmie kubańskiej rumby?<br />
            Zapraszamy na kurs tańców latynoamerykańskich, na zajęciach obecne są:
          </p>
              <ul>
                <li>Samba</li>
                <li>Cha-cha</li>
                <li>Rumba</li>
                <li>Jive</li>
              </ul>
            </>
          </DropDwon>
          <DropDwon title={"Zajęcia grupowe, tańce standardowe"}>
            <>
              <p>
                Jeśli wyjście na parkiet w eleganckim garniturze czy długiej sukni to Twoje marzenie, zapraszamy na kurs tańców standardowych. U nas nauczysz się, jak delikatnie płynąć po parkiecie i pięknie wirować w rytm takich tańców jak:
              </p>
              <ul>
                <li>Walc angielski</li>
                <li>Tango</li>
                <li>Walc wiedeński</li>
                <li>Fokstrot</li>
                <li>Quickstep</li>
              </ul>
            </>
          </DropDwon>
          <DropDwon title={"Latino solo"}>
            <>
              <p>
                Na zajęciach z latino solo nauczysz się dynamicznych, ale zmysłowych ruchów całego ciała, którymi będziesz mieć okazję pochwalić się w rytm latynoamerykańskich utworów.
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Taniec użytkowy"}>
            <>
              <p>
                Jeśli chcesz się nauczyć prostych ruchów, które można wykorzystać na każdej imprezie czy weselu, to są zajęcia dla Ciebie. Idealny kurs dla początkujących, chcących rozpocząć przygodę z tańcem albo dla osób, które szukają dla siebie czegoś nowego.
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Taniec nowoczesny, hip-hop"}>
            <>
              <p>
                Taniec w formacji? Solówka na imprezie? Efektowne, dynamiczne ruchy? Jeśli szukasz nowoczesnego, nieszablonowego tańca, te zajęcia będą dla ciebie idealne.
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Lekcje dla narzeczonych"}>
            <>
              <p>
                Wesele się zbliża i chcecie wypaść jak najlepiej? Poszukujecie profesjonalnego instruktora z dużym doświadczeniem, który przygotuje Wam oryginalną, niezapomnianą choreografię? Zapraszamy na lekcje, które sprawią, że Wasze wesele będzie wyjątkowe!
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Zajęcia indywidualne"}>
            <>
              <p>
                Jeśli chciałbyś mieć zawsze na sobie pełną uwagę instruktora albo zajęcia w grupie po prostu nie są dla Ciebie, zapraszamy na zajęcia indywidualne! Jest to również idealny sposób na to, by znacząco przyspieszyć swój rozwój.
              </p>
              <p>
                Zajęcia indywidualne są również o wiele bardziej elastyczne pod względem terminów. Mogą się one również odbyć w innym miejscu, niż zajęcia grupowe. Wszystkie szczegóły ustalamy z Tobą!              </p>
            </>
          </DropDwon>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Grafik</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <p>
            Zajęcia odbywać będą się na sali w Warszawie przy ulicy Kadrowej 9.
            <a className="FromButton" target="auto:blank" href="https://www.google.pl/maps/place/LI+Liceum+Ogólnokształcące+im.+Tadeusza+Kościuszki+w+Warszawie/@52.2638875,21.1722391,15.75z/data=!4m5!3m4!1s0x471ed1ee09885e1d:0xa4e2f0f8e72d6bcb!8m2!3d52.2652786!4d21.1670646">Zobacz na mapie</a>
          </p>
          <p>
            Właśnie trwa nabór do grup. Zostaw nam do siebie kontakt w formularzu, a my odezwiemy się do Ciebie, by wspólnie ustalić, kiedy będziemy się spotykać. Będziesz miał bezpośredni wpływ na to, kiedy zajęcia będą się odbywać!
          </p>
          <a className="FromButton" href="https://docs.google.com/forms/d/e/1FAIpQLSfbNjkh_vH1nKEYtpm9Gv2NoZzApI99DuqPoWtlGXnIhAAmhg/viewform">Wypełnij Formularz!</a>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          <p>
            <b>
              Telefon:&nbsp;
            </b>
            663-333-399 <br />
            <b>
              Mail:&nbsp;
            </b>
            kontakt@maddance.pl<br />
            Napisz na Facebooku!<br />
            <a href="https://www.facebook.com/MaDdance-Szko%C5%82a-Ta%C5%84ca-100812955552938">
              https://www.facebook.com/MaDDance-szkola-tanca
              </a>


          </p>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> */}
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>

        <article
          id="cennik"
          className={`${this.props.article === 'cennik' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Cennik</h2>
          <p>
            Płatności za zajęcia prosimy uiszczać na konto o numerze: <br />
            58114020040000300268030980<br />
            W tytule prosimy wpisać imię i nazwisko, miesiąc oraz kurs tańca za który uiszczana jest opłata.<br />
            Przykład tytułu:<br />
            <i>Jan Kowalski, wrzesień, grupowe standard</i>
          </p>
          <DropDwon title={"Zajęcia grupowe, tańce latynoamerykańskie"}>
            <>
              <p>
                120 zł / miesiąc, zajęcia raz w tygodniu, 60 min

             </p>
            </>
          </DropDwon>
          <DropDwon title={"Zajęcia grupowe, tańce standardowe"}>
            <>
              <p>
                120 zł / miesiąc, zajęcia raz w tygodniu, 60 min
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Latino solo"}>
            <>
              <p>
                120 zł / miesiąc, zajęcia raz w tygodniu, 60 min
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Taniec użytkowy"}>
            <>
              <p>
                100 zł / miesiąc, zajęcia raz w tygodniu, 60 min
                            </p>
            </>
          </DropDwon>
          <DropDwon title={"Taniec nowoczesny, hip-hop"}>
            <>
              <p>
                120 zł / miesiąc, zajęcia raz w tygodniu, 60 min
                            </p>
            </>
          </DropDwon>

          <DropDwon title={" Pojedyncze wejście na zajęcia"}>
            <>
              <p>
                40 zł / jedne zajęcia
              </p>
            </>
          </DropDwon>

          <DropDwon title={"Lekcje dla narzeczonych"}>
            <>
              <p>
                120 zł / 60 min. Pakiet 8 lekcji: 800 zł / 8*60 min
              </p>
            </>
          </DropDwon>
          <DropDwon title={"Zajęcia indywidualne"}>
            <>
              <p>
                140 zł / 60 min
              </p>

            </>
          </DropDwon>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
